<template lang="pug">
.p-8
  dashboardTitle(:title="`Configuración del menu`")
  p En esta sección puedes excluir o recuperar recetas y alimentos para que tu menu se adapte a tus gustos.
  .grid.grid-cols-2.gap-8.mt-12
    .flex.bg-red-100.p-12
    .flex.bg-blue-100.p-12
</template>
<script>
export default {
  name: "menu.configuration",
};
</script>
<style lang="scss" scoped>
</style>